import React, { useState } from 'react';
import { useHistory } from "react-router";
import { fetch } from "utils/url";

const Register = () => {
    const history = useHistory();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [township, setTownship] = useState('');

    const register = (e) => {
        e.preventDefault();
        fetch({
            url: 'customer',
            method: 'POST',
            body: { name, phone, email, password, township }
        })
            .then(() => fetch({ url: 'customer/authorize', method: 'POST', body: { email, password }}))
            .then(({ token, renewal }) => {
                localStorage.setItem('token', token);
                localStorage.setItem('token', renewal);
                history.push('/zakaznicke-centrum');
            });
    };

    return (
        <div className="register">
            <h1 className="mb-4">Registrace</h1>
            <form onSubmit={register}>
                <input type="text" placeholder="Název obce *" value={township} required onChange={e => setTownship(e.target.value)} />
                <input type="text" placeholder="Jméno a přijmení *" value={name} required onChange={e => setName(e.target.value)} />
                <input type="text" placeholder="Telefon *" value={phone} required onChange={e => setPhone(e.target.value)} />
                <input type="email" placeholder="E-mail *" value={email} required onChange={e => setEmail(e.target.value)} />
                <input type="password" placeholder="Heslo *" value={password} required onChange={e => setPassword(e.target.value)} />
                <input type="submit" value="Registrovat" />
            </form>
        </div>
    );
}

export default Register;
