import React, { useState } from 'react';
import symbol from "../../assets/images/header-symbol.svg";
import logo from "../../assets/images/header-logo.svg";
import Search from "../../Components/Search";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

const Layout = ({ hasSearch, hasMenu, children }) => {
    const history = useHistory();
    const [token, setToken] = useState(localStorage.getItem('token'));

    const logout = () => {
        setToken(null);
        localStorage.clear();
        history.push('/');
    };

    return (
        <div className="container">
            <header>
                <div className="row">
                    <div className="col-md-8 pages">
                        {hasMenu && (
                            <>
                                <Link to="/clanky/clanek/1/o-projektu">O projektu</Link>
                                <Link to="/clanky/clanek/2/autor-projektu">Autoři</Link>
                                <Link to="/clanky/clanek/3/partneri">Partneři</Link>
                            </>
                        )}
                    </div>
                    <div className="col-md-4 d-flex">
                        <div className="login-wrapper ml-auto">
                            {Boolean(token) ? (
                                <>
                                    <Link to="/zakaznicke-centrum">Zákaznické centrum</Link>{' | '}
                                    <button onClick={logout}>Odhlásit</button>
                                </>
                            ) : (
                                <>
                                    <Link to="/registrace">Registrace</Link>
                                    {' | '}
                                    <Link to="/login">Přihlášení</Link>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </header>
            <div className="bg">
                <div className="row">
                    <div className="col-md-2">
                        <div className="sign-wrapper position-relative">
                            <img src={symbol} className="img-fluid header-symbol" alt="sign"/>
                            <Link to="/" className="home-href" />
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="logo-wrapper position-relative">
                            <img src={logo} className="pr-5 img-fluid header-logo" alt="logo" />
                            <Link to="/" className="home-href" />
                        </div>
                    </div>
                </div>
                {hasSearch && <Search />}
                {children}
                <footer>
                    <div className="row">
                        <div className="col-md-8 d-flex align-items-center">
                            <div className="copy">
                                <p className="mb-4 m-md-0">
                                    Mgr. Bc. Luboš Kliment, advokát a pověřenec pro ochranu osobních údajů, e-mail:
                                    kliment@akkliment.cz
                                </p>
                                <p className="mb-4 m-md-0">
                                    All rights Reserved Mgr. et Bc. Luboš Kliment © 2021, Webdesign by{' '}
                                    <a href="https://leclavera.cz" target="_blank" rel="noopener noreferrer">LE CLAVERA s.r.o.</a>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img src={logo} className="pr-5 img-fluid footer-logo" alt="logo"/>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
}

Layout.defaultProps = {
    hasSearch: false,
    hasMenu: false,
};

export default Layout;
