import React, { useState } from 'react';
import { fetch } from "utils/url";
import { useHistory, useParams } from "react-router";

const ResetPassword = () => {
    const history = useHistory();
    const { token } = useParams();
    const [password, setPassword] = useState('');

    const reset = (e) => {
        e.preventDefault();
        fetch({
            url: `customer/reset-password/${token}`,
            method: 'PUT',
            body: { password }
        }).then(({ token, renewal }) => {
            localStorage.setItem('token', token);
            localStorage.setItem('renewal', renewal);
            history.push('/zakaznicke-centrum');
        });
    };

    return (
        <div className="register">
            <h1 className="mb-4">Obnovení hesla</h1>
            <form onSubmit={reset}>
                <input type="password" placeholder="Nové heslo *" value={password} required onChange={e => setPassword(e.target.value)} />
                <input type="submit" value="Obnovit heslo" />
            </form>
        </div>
    );
}

export default ResetPassword;
