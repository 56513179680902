import React from 'react';
import styled from "styled-components";
import classnames from "classnames";
import { range } from "ramda";

const StyledButton = styled.button`
    color: black !important;
    text-decoration: underline;
    &.active {
        background-color: #e9ecef;
    }
`;
const MAX_PAGE_COUNT = 4;

const Paginator = ({ page, pageSize, total, setPage }) => {
    const pageCountTotal = Math.ceil(total/pageSize);
    const pageCount = Math.min(pageCountTotal, MAX_PAGE_COUNT);
    const min = Math.max(page - pageCount, 1);
    const max = Math.min(page + pageCount, pageCountTotal);
    const pages = range(min, max + 1);

    if(pageCount <= 1) {
        return null;
    }

    return (
        <nav aria-label="Page navigation" className="d-flex justify-content-end">
            <ul className="pagination">
                {pages.map((p) => (
                    <li key={p} className='page-item'>
                        <StyledButton className={classnames({ 'page-link': true, active: p === page })} onClick={() => setPage(p)}>{p}</StyledButton>
                    </li>
                ))}
            </ul>
        </nav>
    );
}

export default Paginator;
