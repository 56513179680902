import './assets/css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Router from './Routes/Router';
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

const App = () => (
    <>
        <Helmet>
            <title>Hippocrates.cz</title>
        </Helmet>
        <BrowserRouter>
            <Router />
        </BrowserRouter>
    </>
);


export default App;
