import React from 'react';
import { Switch } from "react-router-dom";
import ProtectedRoute from "Components/ProtectedRoute";

import OfferEdit from "./Components/OfferEdit";
import OfferCreate from "./Components/OfferCreate";
import OfferList from "./Components/OfferList";
import OfferDetail from "./Components/OfferDetail";

const Router = ({ match: { path } }) => (
    <Switch>
        <ProtectedRoute path={`${path}/form/:id`} component={OfferEdit} breadcrumbs="Přihlášen" />
        <ProtectedRoute path={`${path}/form`} component={OfferCreate} breadcrumbs="Přihlášen" />
        <ProtectedRoute path={`${path}/seznam`} component={OfferList} breadcrumbs="Přihlášen" />
        <ProtectedRoute path={`${path}/detail/:id`} component={OfferDetail} breadcrumbs="Přihlášen" />
    </Switch>
);

export default Router;
