import React, { useEffect, useState } from 'react';
import { useDebouncedCallback, useFetch } from "../../utils/hooks";
import { useHistory, useParams } from "react-router";
import { fetch } from "utils/url";
import { formatBytes } from "utils/formaters";

const Documents = () => {
    const [search, setSearch] = useState('');
    const [documents, setDocuments] = useState(null);
    const history = useHistory();
    const { id } = useParams();
    const categories = useFetch('documents/category');
    const category = categories?.data?.find(c => c.id === id);
    const debouncedSearch = useDebouncedCallback(setSearch, 500);

    useEffect(() => {
        const filter = { category_id: id, q: search };
        fetch({ url: `documents/document?filter=${JSON.stringify(filter)}` }).then(setDocuments);
    }, [id, search]);

    return (
        <div className="row">
            <div className="col-md-3">
                {!categories.isLoading && (
                    <div className="document-category-list">
                        {categories.data.map(category => (
                            <button
                                key={category.id}
                                onClick={() => history.push(`/zakaznicke-centrum/kategorie/${category.id}`)}
                            >{category.name}</button>
                        ))}
                    </div>
                )}
            </div>
            {documents && (
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="mb-4 mt-5 mt-md-0">{category?.name}</h2>
                        </div>
                        <div className="col-md-4">
                            <input onChange={e => debouncedSearch(e.target.value)} placeholder="hledat.." className="form-control" />
                        </div>
                    </div>
                    <div className="document-list mt-2 mt-md-0">
                        {documents.map(document => (
                            <div key={document.id} className="document">
                                <div className="row align-items-center">
                                    <div className="col-md-6 text-center text-md-left">{document.name}</div>
                                    <div className="col-md-3 document-info text-center text-md-right">{document.extension.toUpperCase()}, {formatBytes(document.size)}</div>
                                    <div className="col-md-3">
                                        <a
                                            className="download"
                                            href={`/data/documents/${document.id}.${document.extension}`}
                                            download
                                        >
                                            Stáhnout
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Documents;
