import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import debounce from "./debounce";
import { fetch } from "./url";
import { parseRangeFromHeaders } from "./helpers";

export const useFetch = (url, method = 'GET', body = null) => {
    const [data, setData] = useState(null);
    const [headers, setHeaders] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const source = axios.CancelToken.source();
        setIsLoading(true);
        setData(null);
        setHeaders(null);
        setHasError(false);

        fetch({ url, method, body, source, withHeaders: true })
            .then(([data, headers]) => {
                setData(data);
                setHeaders(headers);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setHeaders(null);
                setHasError(true);
            });

        return () => {
            source.cancel();
        }
    }, [url, body, method]);

    return {
        data,
        headers,
        paginator: parseRangeFromHeaders(headers),
        isLoading,
        hasError,
    };
};

/**
 * @description Creates a debounced function that delays invoking func until after wait milliseconds have elapsed since
 * the last time the debounced function was invoked.
 *
 * @param {function} fn - The function to debounce
 * @param {number} delay - The number of milliseconds to delay.
 * @returns {function} Returns the new debounced function.
 */
// eslint-disable-next-line
export const useDebouncedCallback = (fn, delay) => useCallback(debounce(fn, delay), [fn, delay]);

export const usePaginator = ({ page: initPage = 1, pageSize = 10 }) => {
    const [page, setPage] = useState(initPage);
    const countRange = () => [(page-1)*pageSize, pageSize*page];
    const [range, setRange] = useState(countRange());

    useEffect(() => {
        setRange(countRange());
    }, [page]);

    return { range, page, setPage, pageSize };
};
