import React from 'react';
import Layout from 'Routes/Layout/Layout';

const PublicLayout = ({ children }) => (
    <Layout hasSearch={true} hasMenu={true}>
        {children}
    </Layout>
);

export default PublicLayout;
