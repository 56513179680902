import React from 'react';
import { useFetch } from "utils/hooks";
import { findRegion, findDistrict } from 'utils/json/list';
import { useHistory, useParams } from "react-router";

const types = {
    NEW: 'Nová',
    CURRENT: 'Převzetí stávající',
};

const OfferDetail = () => {
    const { id } = useParams();
    const history = useHistory();

    const { data, isLoading } = useFetch(`offer/${id}`);
    const { data: specializations } = useFetch('specialization');
    const findSpecialization = specialization => specializations.find(item => item.value === specialization);

    return !isLoading && Boolean(data) && Boolean(specializations) ? (
        <div className="document-detail">
            <div className="row">
                <div className="col-md-8">
                    <h1 className="mb-5">Detail inzerátu</h1>
                </div>
                <div className="col-md-4 text-right">
                    <button className="advertisement-button d-block d-md-inline-flex ml-0 ml-md-2" onClick={history.goBack}>Zpět</button>
                </div>
            </div>
            <table className="styled left detail-list">
                <tbody>
                <tr>
                    <td>Kraj</td>
                    <td>{findRegion(data.region).label}</td>
                </tr>
                <tr>
                    <td>Okres</td>
                    <td>{findDistrict(data.region, data.district).label}</td>
                </tr>
                <tr>
                    <td>Obec</td>
                    <td>{data.town}</td>
                </tr>
                <tr>
                    <td>Specializace</td>
                    <td>{findSpecialization(data.specialization).label}</td>
                </tr>
                <tr>
                    <td>Dotace / Benefity</td>
                    <td>{data?.benefits || 'Ne'}</td>
                </tr>
                <tr>
                    <td>Typ ordinace</td>
                    <td>{types[data.type]}</td>
                </tr>
                <tr>
                    <td>Kontaktní osoba</td>
                    <td>{data.contactPerson}</td>
                </tr>
                <tr>
                    <td>Telefon</td>
                    <td>{data.phone}</td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>{data.email}</td>
                </tr>
                </tbody>
            </table>
        </div>
    ): null;
}

export default OfferDetail;
