import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { useFetch } from "utils/hooks";
import { serializeToUrlQuery } from "utils/helpers";
import { formatTime } from "utils/formaters";

export const SORT_DIRECTIONS = {
    ASC: "ASC",
    DESC: "DESC"
}

const defaultSort = {
    sortKey: "created_at",
    sortDirection: SORT_DIRECTIONS.DESC
};

const StyledLink = styled(Link)`
    color: black !important;
    text-decoration: underline;
`;

const StyledContent = styled.div`
    color: #6E6E6E;
`;

const StyledMoreLink = styled(Link)`
    border-radius: 25px;
    background-color: #f4f4f4;
    border: 2px solid white;
    padding: 6px 25px;
    color: black !important;
    text-decoration: none !important;
`;

export const ArticleListRender = ({ data }) => (
    <div className="row">
        {data.map(item => (
            <div key={item.id} className="col-12 col-md-4 mb-3">
                <h5>{item.title}</h5>
                <hr className="mt-3 mb-2" />
                <div className="mb-1">
                    <small>{formatTime(item.created_at)}</small>
                </div>
                <StyledContent dangerouslySetInnerHTML={{ __html: item.perex }} />
                <StyledLink to={`/clanky/clanek/${item.id}/${item.alias}`}>více</StyledLink>
            </div>
        ))}
    </div>
);

const ArticleListModule = ({ moreUrl, categoryId, sort: { sortKey, sortDirection } = defaultSort, pageSize }) => {
    const params = serializeToUrlQuery({
        filter: {
            category_id: categoryId,
        },
        range: [0, pageSize],
        sort: [sortKey, sortDirection]
    });
    const { data, isLoading } = useFetch(`articles/article?${params}`);

    if(isLoading || !data?.length) {
        return null;
    }

    return (
        <div className="content main">
            <ArticleListRender data={data} />
            <div className="d-flex justify-content-center mt-4">
                <StyledMoreLink to={moreUrl}>Další články</StyledMoreLink>
            </div>
        </div>
    );
}

export default ArticleListModule;
