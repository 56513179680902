import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router";
import { findRegion, findDistrict } from "utils/json/list";
import { Link } from "react-router-dom";
import { fetch } from "utils/url";
import { useFetch } from "utils/hooks";

const OfferList = () => {
    const history = useHistory();
    const [selectedData, setSelectedData] = useState(null);
    const [offers, setOffers] = useState(null);
    const { data: specializations } = useFetch('specialization');

    const parseDataFromUrl = ({ search }) => {
        const urlParams = new URLSearchParams(search);
        setSelectedData({
            specialization: urlParams.get('specialization'),
            district: urlParams.get('district'),
            region: urlParams.get('region'),
        });
    };

    useEffect(() => {
        parseDataFromUrl({ search: history.location.search });
        history.listen(parseDataFromUrl);
    }, [history]);

    useEffect(() => {
        fetch({ url: `offer?filter=${JSON.stringify(selectedData)}` }).then(setOffers);
    }, [selectedData]);

    if(!offers?.length || !Boolean(specializations)) {
        return <h4>Nebyly nalezeny žádné záznamy.</h4>;
    }

    return (
        <div className="document-list">
            <h1 className="mb-5">Výsledky vyhledávání</h1>
            <div className="header mb-3">
                <div className="text">
                    {selectedData.region && `Kraj: ${findRegion(selectedData.region).label}`}
                    {selectedData.district && `, Okres: ${findDistrict(selectedData.region, selectedData.district).label}`}
                </div>
            </div>
            <div className="list">
                {offers.map(offer => (
                    <div key={offer.id} className="document">
                        <div className="row align-items-center">
                            <div className="col-md-4">
                                <strong>{offer.town}</strong>
                            </div>
                            <div className="col-md-4">
                                {specializations.find(s => offer.specialization === s.value).label}
                            </div>
                            <div className="col-md-4 d-flex">
                                <Link to={`/nabidky/detail/${offer.id}`} className="search-detail">Detail</Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OfferList;
