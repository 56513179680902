import React from 'react';
import { Switch } from "react-router-dom";
import AppRoute from "Components/AppRoute";

import ResetPassword from "./Components/ResetPassword";
import ResetPasswordRequest from "./Components/ResetPasswordRequest";

const Router = ({ match: { path } }) => (
    <Switch>
        <AppRoute path={`${path}/:token`} component={ResetPassword} breadcrumbs="Obnovení hesla" />
        <AppRoute path={path} component={ResetPasswordRequest} breadcrumbs="Zapomenuté heslo" />
    </Switch>
);

export default Router;
