/**
 *
 * @param fn
 * @param delay
 * @see https://codeburst.io/throttling-and-debouncing-in-javascript-646d076d0a44
 * @returns {Function}
 */
export default function debounce(fn, delay) {
    let timerId;
    return function debounced(...args) {
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            fn(...args);
            timerId = null;
        }, delay);
    };
}
