import React from 'react';
import { Switch } from "react-router-dom";
import AppRoute from "Components/AppRoute";

import OfferDetail from "./Components/OfferDetail";
import OfferList from "./Components/OfferList";

const Router = ({ match: { path } }) => (
    <Switch>
        <AppRoute path={`${path}/detail/:id`} component={OfferDetail} breadcrumbs="Vyhledávání" />
        <AppRoute path={path} component={OfferList} breadcrumbs="Vyhledávání" />
    </Switch>
);

export default Router;
