import React from 'react';
import OfferForm from "./OfferForm";
import { useParams } from "react-router";
import { useFetch } from "utils/hooks";
import { findDistrict, findRegion } from "utils/json/list";

const types = [{
    value: 'NEW',
    label: 'Nová',
},{
    value: 'CURRENT',
    label: 'Převzetí stávající',
}];

const OfferCreate = () => {
    const { id } = useParams();
    const { data, isLoading } = useFetch(`offer/${id}`);
    const { data: specializations } = useFetch('specialization');
    const findSpecialization = specialization => specializations.find(item => item.value === specialization);
    const findType = type => types.find(item => item.value === type);

    return (
        <>
            <h1 className="mb-4">Zadání inzerátu</h1>
            {!isLoading && Boolean(specializations) && (
                <OfferForm
                    {...data}
                    id={id}
                    region={findRegion(data.region)}
                    district={findDistrict(data.region, data.district)}
                    specialization={findSpecialization(data.specialization)}
                    hasBenefits={data.benefits?.length}
                    type={findType(data.type)}
                />
            )}
        </>
    );
}

export default OfferCreate;
