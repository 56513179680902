import React, {useState} from 'react';
import {fetch} from "utils/url";

const Form = ({ email, setEmail, reset }) => (
    <form onSubmit={reset}>
        <input type="email" placeholder="E-mail *" value={email} required onChange={e => setEmail(e.target.value)} />
        <input type="submit" value="Resetovat heslo" />
    </form>
);

const ResetPasswordRequest = () => {
    const [email, setEmail] = useState('');
    const [isSend, setIsSend] = useState(false);

    const reset = (e) => {
        e.preventDefault();
        fetch({
            url: 'customer/reset-password',
            method: 'POST',
            body: { email }
        }).then(() => setIsSend(true));
    };

    return (
        <div className="register">
            <h1 className="mb-4">Zapomenuté heslo</h1>
            {isSend ? (
                <p>
                    Právě jsme Vám odeslali e-mail na adresu <strong>{email}</strong> s odkazem na obnovení hesla.
                </p>
            ) : (
                <Form reset={reset} email={email} setEmail={setEmail} />
            )}
        </div>
    );
}

export default ResetPasswordRequest;
