import React from 'react';
import { useFetch } from "utils/hooks";
import { useParams } from "react-router";
import Breadcrumbs from "Components/Breadcrumbs";
import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon,
} from "react-share";
import { Helmet } from "react-helmet";

const Article = () => {
    const { id } = useParams();
    const { data, isLoading } = useFetch(`articles/article/${id}`);

    if(isLoading) {
        return null;
    }

    return (
        <div className="content">
            <Helmet>
                <title>{data.title}</title>
                <meta property="og:url"           content={window.location.href} />
                <meta property="og:type"          content="website" />
                <meta property="og:title"         content={data.title} />
                <meta property="og:description"   content={data.perex} />
            </Helmet>
            <Breadcrumbs breadcrumbs={data.title} />
            <main>
                {data.perex && (
                    <div className="article" dangerouslySetInnerHTML={{ __html: data.perex }} />
                )}
                {data.content && (
                    <div className="article" dangerouslySetInnerHTML={{ __html: data.content }} />
                )}
                <FacebookShareButton
                    url={"https://hippocrates.cz/clanky/clanek/7/mala-domu"}
                    title={data.title}
                    quote={data.perex.replace(/<\/?[^>]+(>|$)/g, "").replace(/\&nbsp;/g, " ")}
                    className="pr-1"
                >
                    <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <TwitterShareButton
                    url={window.location.href}
                    title={data.title}
                    description={data.perex}
                >
                    <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
            </main>
        </div>
    )
}

export default Article;
