import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router";
import { fetch } from "utils/url";
import { useFetch } from "utils/hooks";
import { getRegions, getDistricts } from 'utils/json/list';
import Select from "react-select";

const types = [{
    value: 'NEW',
    label: 'Nová',
},{
    value: 'CURRENT',
    label: 'Převzetí stávající',
}];

const OfferForm = (offer) => {
    const history = useHistory();
    const [region, setRegion] = useState(offer.region);
    const [district, setDistrict] = useState(offer.district);
    const [specialization, setSpecialization] = useState(offer.specialization);
    const [town, setTown] = useState(offer.town);
    const [hasBenefits, setHasBenefits] = useState(offer.hasBenefits);
    const [benefits, setBenefits] = useState(offer.benefits || '');
    const [type, setType] = useState(offer.type);
    const [contactPerson, setContactPerson] = useState(offer.contactPerson);
    const [phone, setPhone] = useState(offer.phone);
    const [email, setEmail] = useState(offer.email);
    const { isLoading, data: specializations } = useFetch('specialization');
    const benefitRef = useRef(null);

    const register = (e) => {
        e.preventDefault();
        const body = {
            region: region.value,
            district: district.value,
            specialization: specialization.value,
            town,
            benefits: hasBenefits ? benefits: null,
            type: type.value,
            contactPerson,
            phone,
            email
        };
        const url = offer.id ? `offer/${offer.id}` : 'offer';
        const method = offer.id ? 'PUT' : 'POST';

        fetch({ url, method, body })
            .then(() => history.push('/zakaznicke-centrum/inzerat/seznam'));
    };

    useEffect(() => {
        if(hasBenefits) {
            benefitRef.current.focus();
        }
    }, [hasBenefits]);

    if(isLoading) {
        return null;
    }

    return (
        <form onSubmit={register}>
            <div className="row">
                <div className="col-md-3">
                    <Select
                        className="form-select"
                        value={specialization}
                        options={specializations}
                        placeholder="Obor *"
                        isSearchable
                        required
                        onChange={setSpecialization}
                    />
                </div>
                <div className="col-md-3">
                    <Select
                        className="form-select"
                        value={region}
                        options={getRegions()}
                        placeholder="Kraj *"
                        isSearchable
                        required
                        onChange={setRegion}
                    />
                </div>
                <div className="col-md-3">
                    <Select
                        className="form-select"
                        value={district}
                        options={getDistricts(region)}
                        placeholder="Okres *"
                        isSearchable
                        required
                        onChange={setDistrict}
                    />
                </div>
                <div className="col-md-3">
                    <input
                        type="text"
                        placeholder="Obec / Město *"
                        value={town}
                        required
                        onChange={e => setTown(e.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <input
                        type="text"
                        placeholder="Kontaktní osoba *"
                        value={contactPerson}
                        required
                        onChange={e => setContactPerson(e.target.value)}
                    />
                </div>
                <div className="col-md-4">
                    <input
                        type="text"
                        placeholder="Telefon *"
                        value={phone}
                        required
                        onChange={e => setPhone(e.target.value)}
                    />
                </div>
                <div className="col-md-4">
                    <input
                        type="email"
                        placeholder="E-mail *"
                        value={email}
                        required
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>
            </div>
            <Select
                className="form-select"
                value={type}
                options={types}
                placeholder="Typ ordinace *"
                isSearchable
                onChange={setType}
            />
            <div className="input-group mb-1">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <input type="checkbox" value={hasBenefits} onChange={e => setHasBenefits(e.target.checked)} />
                    </span>
                </div>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Dotace / benefity *"
                    value={benefits}
                    onChange={e => setBenefits(e.target.value)}
                    ref={benefitRef}
                    disabled={!hasBenefits}
                />
            </div>
            <input type="submit" value="Uložit inzerát" />
        </form>
    );
}

OfferForm.defaultProps = {
    region: '',
    district: '',
    specialization: '',
    town: '',
    hasBenefits: false,
    benefits: '',
    type: '',
    contactPerson: '',
    phone: '',
    email: ''
};

export default OfferForm;
