import React, { useState } from 'react';
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { fetch } from "utils/url";

const Login = () => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const login = (e) => {
        e.preventDefault();
        fetch({ url: 'customer/authorize', method: 'POST', body: { email, password }})
            .then(({ token, renewal }) => {
                localStorage.setItem('token', token);
                localStorage.setItem('renewal', renewal);
                history.push('/zakaznicke-centrum');
            });
    };

    return (
        <div className="login">
            <h1 className="mb-4">Přihlášení</h1>
            <form onSubmit={login}>
                <input type="email" placeholder="E-mail" value={email} onChange={e => setEmail(e.target.value)} />
                <input type="password" placeholder="Heslo" value={password} onChange={e => setPassword(e.target.value)} />
                <Link className="lost-password" to="/zapomenute-heslo">Zapomenuté heslo</Link>
                <input type="submit" value="Přihlásit" />
            </form>
        </div>
    );
}

export default Login;
