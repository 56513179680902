export const serializeToUrlQuery = params => new URLSearchParams(Object.fromEntries(Object.entries(params).map(([key, val]) => [key, JSON.stringify(val)]))).toString();

export const parseRangeFromHeaders = (headers) => {
    const range = headers?.['content-range'];

    if(!range) {
        return { since: null, until: null, total: null };
    }

    const [pagination, total] = range.split('/');
    const [since, until] = pagination.split('-');

    return { since, until, total };
};
