import React from 'react';
import Layout from 'Routes/Layout/Layout';

const ProtectedLayout = ({ children }) => (
    <Layout hasSearch={false} hasMenu={false}>
        {children}
    </Layout>
);

export default ProtectedLayout;
