import React from 'react';
import { Switch } from "react-router-dom";
import { Route } from "react-router";
import AppRoute from "Components/AppRoute";

import PublicLayout from './Layout/PublicLayout';
import OfferRouter from "./Offer/Router";
import ResetPasswordRouter from "./ResetPassword/Router";
import ArticleRouter from "../Common/Article/Router";
import Homepage from "./Homepage";
import Register from "./Register";
import Login from "./Login";

const Router = () => (
    <PublicLayout>
        <Switch>
            <Route path="/clanky" component={ArticleRouter} />
            <Route path="/nabidky" component={OfferRouter} />
            <Route path="/zapomenute-heslo" component={ResetPasswordRouter} />
            <AppRoute path="/registrace" component={Register} breadcrumbs="Registrace" />
            <AppRoute path="/login" component={Login} breadcrumbs="Registrace" />
            <Route path="/" component={Homepage} exact />
        </Switch>
    </PublicLayout>
);

export default Router;
