import { Route } from "react-router-dom";
import React from "react";
import Breadcrumbs from "./Breadcrumbs";

const AppRoute = ({ path, exact, component: Component, breadcrumbs }) => (
    <Route path={path} exact={exact} render={props => (
        <div className="content">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <main>
                <Component {...props} />
            </main>
        </div>
    )} />
);

AppRoute.defaultProps = {
    hasSearch: false,
    hasMenu: false,
    breadcrumbs: '',
};

export default AppRoute;
