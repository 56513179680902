const list = {
    "CZ010": {
        "value": "CZ010",
        "label": "Hlavní město Praha",
        "districtList": {
            "CZ0100": {
                "value": "CZ0100",
                "label": "Praha (okres)"
            }
        }
    },
    "CZ020": {
        "value": "CZ020",
        "label": "Středočeský kraj",
        "districtList": {
            "CZ0201": {
                "value": "CZ0201",
                "label": "Benešov"
            },
            "CZ0202": {
                "value": "CZ0202",
                "label": "Beroun"
            },
            "CZ0203": {
                "value": "CZ0203",
                "label": "Kladno"
            },
            "CZ0204": {
                "value": "CZ0204",
                "label": "Kolín"
            },
            "CZ0205": {
                "value": "CZ0205",
                "label": "Kutná Hora"
            },
            "CZ0206": {
                "value": "CZ0206",
                "label": "Mělník"
            },
            "CZ0207": {
                "value": "CZ0207",
                "label": "Mladá Boleslav"
            },
            "CZ0208": {
                "value": "CZ0208",
                "label": "Nymburk"
            },
            "CZ0209": {
                "value": "CZ0209",
                "label": "Praha-východ"
            },
            "CZ020A": {
                "value": "CZ020A",
                "label": "Praha-západ"
            },
            "CZ020B": {
                "value": "CZ020B",
                "label": "Příbram"
            },
            "CZ020C": {
                "value": "CZ020C",
                "label": "Rakovník"
            }
        }
    },
    "CZ031": {
        "value": "CZ031",
        "label": "Jihočeský kraj",
        "districtList": {
            "CZ0311": {
                "value": "CZ0311",
                "label": "České Budějovice"
            },
            "CZ0312": {
                "value": "CZ0312",
                "label": "Český Krumlov"
            },
            "CZ0313": {
                "value": "CZ0313",
                "label": "Jindřichův Hradec"
            },
            "CZ0314": {
                "value": "CZ0314",
                "label": "Písek"
            },
            "CZ0315": {
                "value": "CZ0315",
                "label": "Prachatice"
            },
            "CZ0316": {
                "value": "CZ0316",
                "label": "Strakonice"
            },
            "CZ0317": {
                "value": "CZ0317",
                "label": "Tábor"
            }
        }
    },
    "CZ032": {
        "value": "CZ032",
        "label": "Plzeňský kraj",
        "districtList": {
            "CZ0321": {
                "value": "CZ0321",
                "label": "Domažlice"
            },
            "CZ0322": {
                "value": "CZ0322",
                "label": "Klatovy"
            },
            "CZ0323": {
                "value": "CZ0323",
                "label": "Plzeň-město"
            },
            "CZ0324": {
                "value": "CZ0324",
                "label": "Plzeň-jih"
            },
            "CZ0325": {
                "value": "CZ0325",
                "label": "Plzeň-sever"
            },
            "CZ0326": {
                "value": "CZ0326",
                "label": "Rokycany"
            },
            "CZ0327": {
                "value": "CZ0327",
                "label": "Tachov"
            }
        }
    },
    "CZ041": {
        "value": "CZ041",
        "label": "Karlovarský kraj",
        "districtList": {
            "CZ0411": {
                "value": "CZ0411",
                "label": "Cheb"
            },
            "CZ0412": {
                "value": "CZ0412",
                "label": "Karlovy Vary"
            },
            "CZ0413": {
                "value": "CZ0413",
                "label": "Sokolov"
            }
        }
    },
    "CZ042": {
        "value": "CZ042",
        "label": "Ústecký kraj",
        "districtList": {
            "CZ0421": {
                "value": "CZ0421",
                "label": "Děčín"
            },
            "CZ0422": {
                "value": "CZ0422",
                "label": "Chomutov"
            },
            "CZ0423": {
                "value": "CZ0423",
                "label": "Litoměřice"
            },
            "CZ0424": {
                "value": "CZ0424",
                "label": "Louny"
            },
            "CZ0425": {
                "value": "CZ0425",
                "label": "Most"
            },
            "CZ0426": {
                "value": "CZ0426",
                "label": "Teplice"
            },
            "CZ0427": {
                "value": "CZ0427",
                "label": "Ústí nad Labem"
            }
        }
    },
    "CZ051": {
        "value": "CZ051",
        "label": "Liberecký kraj",
        "districtList": {
            "CZ0511": {
                "value": "CZ0511",
                "label": "Česká Lípa"
            },
            "CZ0512": {
                "value": "CZ0512",
                "label": "Jablonec nad Nisou"
            },
            "CZ0513": {
                "value": "CZ0513",
                "label": "Liberec"
            },
            "CZ0514": {
                "value": "CZ0514",
                "label": "Semily"
            }
        }
    },
    "CZ052": {
        "value": "CZ052",
        "label": "Královéhradecký kraj",
        "districtList": {
            "CZ0521": {
                "value": "CZ0521",
                "label": "Hradec Králové"
            },
            "CZ0522": {
                "value": "CZ0522",
                "label": "Jičín"
            },
            "CZ0523": {
                "value": "CZ0523",
                "label": "Náchod"
            },
            "CZ0524": {
                "value": "CZ0524",
                "label": "Rychnov nad Kněžnou"
            },
            "CZ0525": {
                "value": "CZ0525",
                "label": "Trutnov"
            }
        }
    },
    "CZ053": {
        "value": "CZ053",
        "label": "Pardubický kraj",
        "districtList": {
            "CZ0531": {
                "value": "CZ0531",
                "label": "Chrudim"
            },
            "CZ0532": {
                "value": "CZ0532",
                "label": "Pardubice"
            },
            "CZ0533": {
                "value": "CZ0533",
                "label": "Svitavy"
            },
            "CZ0534": {
                "value": "CZ0534",
                "label": "Ústí nad Orlicí"
            }
        }
    },
    "CZ063": {
        "value": "CZ063",
        "label": "Kraj Vysočina",
        "districtList": {
            "CZ0631": {
                "value": "CZ0631",
                "label": "Havlíčkův Brod"
            },
            "CZ0632": {
                "value": "CZ0632",
                "label": "Jihlava"
            },
            "CZ0633": {
                "value": "CZ0633",
                "label": "Pelhřimov"
            },
            "CZ0634": {
                "value": "CZ0634",
                "label": "Třebíč"
            },
            "CZ0635": {
                "value": "CZ0635",
                "label": "Žďár nad Sázavou"
            }
        }
    },
    "CZ064": {
        "value": "CZ064",
        "label": "Jihomoravský kraj",
        "districtList": {
            "CZ0641": {
                "value": "CZ0641",
                "label": "Blansko"
            },
            "CZ0642": {
                "value": "CZ0642",
                "label": "Brno-město"
            },
            "CZ0643": {
                "value": "CZ0643",
                "label": "Brno-venkov"
            },
            "CZ0644": {
                "value": "CZ0644",
                "label": "Břeclav"
            },
            "CZ0645": {
                "value": "CZ0645",
                "label": "Hodonín"
            },
            "CZ0646": {
                "value": "CZ0646",
                "label": "Vyškov"
            },
            "CZ0647": {
                "value": "CZ0647",
                "label": "Znojmo"
            }
        }
    },
    "CZ071": {
        "value": "CZ071",
        "label": "Olomoucký kraj",
        "districtList": {
            "CZ0711": {
                "value": "CZ0711",
                "label": "Jeseník"
            },
            "CZ0712": {
                "value": "CZ0712",
                "label": "Olomouc"
            },
            "CZ0713": {
                "value": "CZ0713",
                "label": "Prostějov"
            },
            "CZ0714": {
                "value": "CZ0714",
                "label": "Přerov"
            },
            "CZ0715": {
                "value": "CZ0715",
                "label": "Šumperk"
            }
        }
    },
    "CZ072": {
        "value": "CZ072",
        "label": "Zlínský kraj",
        "districtList": {
            "CZ0721": {
                "value": "CZ0721",
                "label": "Kroměříž"
            },
            "CZ0722": {
                "value": "CZ0722",
                "label": "Uherské Hradiště"
            },
            "CZ0723": {
                "value": "CZ0723",
                "label": "Vsetín"
            },
            "CZ0724": {
                "value": "CZ0724",
                "label": "Zlín"
            }
        }
    },
    "CZ080": {
        "value": "CZ080",
        "label": "Moravskoslezský kraj",
        "districtList": {
            "CZ0801": {
                "value": "CZ0801",
                "label": "Bruntál"
            },
            "CZ0802": {
                "value": "CZ0802",
                "label": "Frýdek-Místek"
            },
            "CZ0803": {
                "value": "CZ0803",
                "label": "Karviná"
            },
            "CZ0804": {
                "value": "CZ0804",
                "label": "Nový Jičín"
            },
            "CZ0805": {
                "value": "CZ0805",
                "label": "Opava"
            },
            "CZ0806": {
                "value": "CZ0806",
                "label": "Ostrava-město"
            }
        }
    }
};

export const getRegions = () => Object.values(list).sort((a, b) => a.label.localeCompare(b.label));
export const getDistricts = region => region ? Object.values(region.districtList).map(({ value, label }) => ({ value, label })) : [];
export const findRegion = region => list[region];
export const findDistrict = (region, district) => findRegion(region).districtList[district];