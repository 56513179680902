import React, { useEffect, useState } from 'react';
import decodeJwt from "jwt-decode";
import { Link } from "react-router-dom";

import { useFetch } from "utils/hooks";
import { fetch } from "utils/url";

const OfferList = () => {
    const { jti } = decodeJwt(localStorage.getItem('token'));
    const [data, setData] = useState();
    const { data: specializations } = useFetch('specialization');

    const deleteOffer = (id) => fetch({ url: `offer/${id}`, method: 'DELETE' })
        .then(() => fetch({ url: `offer?filter=${JSON.stringify({ customer_id: jti })}` }))
        .then(setData);

    useEffect(() => {
        fetch({ url: `offer?filter=${JSON.stringify({ customer_id: jti })}` }).then(setData);
    }, [jti]);

    if(!Boolean(data) || !Boolean(specializations)) {
        return null;
    }

    return (
        <div className="document-list">
            <h1 className="mb-5">Seznam inzerátů</h1>

            <div className="list">
                {data.map(offer => (
                    <div key={offer.id} className="document">
                        <div className="row align-items-center text-center text-md-left">
                            <div className="col-md-4">
                                <strong>{offer.town}</strong>
                            </div>
                            <div className="col-md-3">
                                {specializations.find(s => offer.specialization === s.value).label}
                            </div>
                            <div className="col-md-5 d-block d-md-flex pt-2 pt-md-0">
                                <Link to={`/zakaznicke-centrum/inzerat/detail/${offer.id}`} className="search-detail d-block d-md-inline-flex w-100 mb-2 mb-md-0 text-center">Detail</Link>
                                <Link to={`/zakaznicke-centrum/inzerat/form/${offer.id}`} className="search-detail d-block d-md-inline-flex w-100 mb-2 mb-md-0 text-center ml-0 ml-md-2">Editovat</Link>
                                <button onClick={() => deleteOffer(offer.id)} className="search-detail d-block d-md-inline-flex w-100 mb-2 mb-md-0 ml-0 ml-md-2">Smazat</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OfferList;
