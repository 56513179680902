import React from 'react';
import { useParams } from "react-router";
import { useFetch, usePaginator } from "utils/hooks";
import { serializeToUrlQuery } from "utils/helpers";
import Breadcrumbs from "Components/Breadcrumbs";
import Paginator from "Components/Paginator";
import { ArticleListRender, SORT_DIRECTIONS } from "../Modules/ArticleList";

const ArticleList = () => {
    const { id: categoryId } = useParams();
    const { range, setPage, page, pageSize } = usePaginator({ pageSize: 9 });
    const params = serializeToUrlQuery({
        filter: {
            category_id: categoryId,
        },
        range,
        sort: ["created_at", SORT_DIRECTIONS.DESC]
    });

    const { data: categoryData, isLoading: categoryIsLoading } = useFetch(`articles/category/${categoryId}`);
    const { data: articleData, paginator: { total }, isLoading: articleIsLoading } = useFetch(`articles/article?${params}`);

    if(categoryIsLoading || articleIsLoading) {
        return null;
    }

    return (
        <div className="content">
            <Breadcrumbs breadcrumbs={categoryData.name} />
            <main>
                <ArticleListRender data={articleData} />
                <Paginator setPage={setPage} page={page} pageSize={pageSize} total={total} />
            </main>
        </div>
    )
}

export default ArticleList;
