import React, {useState} from 'react';
import Select from 'react-select';
import { getDistricts, getRegions } from '../utils/json/list';
import { encodeDataToURL } from "../utils/url";
import { useHistory } from "react-router";
import { useFetch } from "../utils/hooks";

const Search = () => {
    const history = useHistory();
    const [region, setRegion] = useState(null);
    const [district, setDistrict] = useState(null);
    const [specialization, setSpecialization] = useState(null);
    const { data: specializations } = useFetch('specialization');

    const search = () => {
        history.push(`/nabidky?${encodeDataToURL({ region, district, specialization })}`)
    };

    return (
        <div className="row mt-3">
            <div className="col-md-2 d-block d-md-flex align-items-center">
                <h6 className="text-center text-md-right mb-md-0">NABÍDKA ORDINACE</h6>
            </div>
            <div className="col-md-7">
                <div className="row pl-3">
                    <div className="col-md-4 p-md-0">
                        <Select
                            className="search-select mb-2 mb-md-0"
                            value={specialization}
                            options={specializations}
                            placeholder="Obor"
                            isSearchable
                            onChange={setSpecialization}
                        />
                    </div>
                    <div className="col-md-4 p-md-0">
                        <Select
                            className="search-select mb-2 mb-md-0"
                            value={region}
                            options={getRegions()}
                            placeholder="Kraj"
                            isSearchable
                            onChange={r => {
                                setRegion(r);
                                setDistrict(null);
                            }}
                        />
                    </div>
                    <div className="col-md-4 p-md-0">
                        <Select
                            className="search-select mb-2 mb-md-0"
                            value={district}
                            options={getDistricts(region)}
                            placeholder="Okres"
                            isSearchable
                            onChange={setDistrict}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <button className="search-button ml-auto d-flex mr-auto mr-md-0" onClick={search}>Vyhledat nabídky</button>
            </div>
        </div>
    );
}

export default Search;
