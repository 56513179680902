import React from 'react';
import { Switch } from "react-router-dom";
import { Route } from "react-router";
import ProtectedRoute from "Components/ProtectedRoute";

import ProtectedLayout from './Layout/ProtectedLayout';
import Dashboard from './Dashboard';
import CustomerDocuments from './Documents';
import OfferRouter from './Offer/Router';
import ArticleRouter from "../Common/Article/Router";

const Router = ({ match: { path } }) => (
    <ProtectedLayout>
        <Switch>
            <Route path={`${path}/inzerat`} component={OfferRouter} />
            <Route path={`${path}/clanky`} component={ArticleRouter} />
            <ProtectedRoute path={`${path}/kategorie/:id`} component={CustomerDocuments} breadcrumbs="Přihlášen" />
            <ProtectedRoute path={path} component={Dashboard} breadcrumbs="Přihlášen" />
        </Switch>
    </ProtectedLayout>
);

export default Router;
