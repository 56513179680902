import React from 'react';
import { Link } from "react-router-dom";
import Breadcrumbs from "Components/Breadcrumbs";
import ArticleList from "../Common/Article/Modules/ArticleList";

const Homepage = () => (
    <>
        <div className="content">
            <Breadcrumbs breadcrumbs="Informace" />
            <main>
                <div className="row justify-content-center">
                    <div className="col-md-4">
                        <Link to="/clanky/clanek/1/o-projektu" className="text-dark">
                            <div className="box justify-content-center justify-content-md-end pr-0 pr-md-5 mb-3">
                                <div className="inner">O projektu</div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-4">
                        <Link to="/clanky/clanek/2/autor-projektu" className="text-dark">
                            <div className="box justify-content-center justify-content-md-end pr-0 pr-md-5 mb-3">
                                <div className="inner">Autoři projektu</div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-4">
                        <Link to="/clanky/clanek/3/partneri" className="text-dark">
                            <div className="box justify-content-center justify-content-md-end pr-0 pr-md-5 mb-3">
                                <div className="inner">Partneři</div>
                            </div>
                        </Link>
                    </div>
                </div>
            </main>
        </div>
        <div className="mt-5">
            <ArticleList moreUrl="/clanky/sekce/2/aktuality" categoryId={2} pageSize={6} />
        </div>
    </>
);

export default Homepage;
