import React from 'react';
import { useFetch } from "utils/hooks";
import {useHistory} from "react-router";

const Dashboard = () => {
    const history = useHistory();
    const { data, isLoading } = useFetch('documents/category');

    if(isLoading) {
        return null;
    }

    return (
        <div className="row justify-content-center">
            {data.map(category => (
                <div key={category.id} className="col-md-4">
                    <div className="box mb-3" onClick={() => history.push(`/zakaznicke-centrum/kategorie/${category.id}`)}>
                        <div className="inner">{category.name}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Dashboard;
