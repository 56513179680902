import React from 'react';
import { Switch, Route } from "react-router-dom";
import ProtectedRouter from './Protected/Router';
import PublicRouter from './Public/Router';

const Router = () => (
    <Switch>
        <Route path="/zakaznicke-centrum" component={ProtectedRouter} />
        <Route path="/" component={PublicRouter} />
    </Switch>
);

export default Router;
