import React from 'react';
import OfferForm from "./OfferForm";

const OfferCreate = () => (
    <>
        <h1 className="mb-4">Zadání inzerátu</h1>
        <OfferForm />
    </>
);

export default OfferCreate;
