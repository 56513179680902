import React from 'react';

const Breadcrumbs = ({ breadcrumbs }) => (
    <table className="content-top">
        <tbody>
            <tr>
                <td width="55%">
                    <div className="ml-4">{breadcrumbs}</div>
                </td>
                <td width="45px"></td>
                <td width="45px"></td>
                <td width="45px"></td>
                <td></td>
            </tr>
        </tbody>
    </table>
);

export default Breadcrumbs;
