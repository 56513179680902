import {Link, Route} from "react-router-dom";
import React from "react";
import Breadcrumbs from "./Breadcrumbs";
import decodeJwt from "jwt-decode";
import {useFetch} from "../utils/hooks";

const Header = () => {
    const { jti } = decodeJwt(localStorage.getItem('token'));
    const { data, isLoading } = useFetch(`customer/${jti}`);

    if(isLoading) {
        return null;
    }

    return (
        <div className="header align-items-center d-block d-md-flex">
            <span className="text text-center text-md-left mb-3 mb-md-0 d-block d-md-inline-block">Obec {data.township}</span>
            <div className="ml-auto">
                <Link to="/zakaznicke-centrum/inzerat/seznam" className="advertisement-button d-block d-md-inline-flex mb-2 mb-md-0">Seznam inzerátů</Link>
                <Link to="/zakaznicke-centrum/inzerat/form" className="advertisement-button d-block d-md-inline-flex ml-0 ml-md-2">Vložit inzerát</Link>
            </div>
        </div>
    );
};

const ProtectedRoute = ({ path, exact, component: Component, breadcrumbs }) => (
    <Route path={path} exact={exact} render={props => (
        <div className="content">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <main>
                <div className="customer">
                    <Header />
                    <Component {...props} />
                </div>
            </main>
        </div>
    )} />
);

ProtectedRoute.defaultProps = {
    hasSearch: false,
    hasMenu: false,
    breadcrumbs: '',
};

export default ProtectedRoute;
