import React from "react";
import Article from "./Components/Article";
import ArticleList from "./Components/ArticleList";
import { Switch } from "react-router-dom";
import { Route } from "react-router";

const Router = ({ match: { path } }) => (
    <Switch>
        <Route path={`${path}/clanek/:id/:alias`} component={Article} />
        <Route path={`${path}/sekce/:id/:alias`} component={ArticleList} />
    </Switch>
);

export default Router;
